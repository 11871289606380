@import "src/client_customizations/scss/client_master.scss";

.hcFilterControls {
    .socket-text {
        float: left;
    }
    .socket-image {
        float: right;
    }
    .form-group {
        .wifi-icon {
            padding-right: 8px;
        }
    }
    hr {
        margin: 2rem 0rem;
    }
    .find-electricians {
        text-align: center;
        p {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
        }
        .btn-electricians {
            color: white;
            background-color: #2A2A2A;
            padding: 6px 12px;
            border: none;
        }
    }
}