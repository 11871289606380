@import "src/client_customizations/scss/client_master.scss";

.faqContainer {
    .faq {
        font-style: normal;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.25rem;
        text-transform: uppercase;
        border-bottom: 1px solid #D9D9D9;
        padding-bottom: 48px;
    }

    .collapsibleSectionCollaspeButton {
        width: 2rem;
        height: 2rem;
        border-radius: 100%;
        background-color: white;
    }

    .contentShow .collapsibleSectionBodyContent {
        opacity: 1;
        height: 100%;
        transition: all .80s linear;
        

    }

    .contentHide .collapsibleSectionBodyContent {
        visibility: hidden;
        height: 0;
        opacity: 0.01;
        transition: opacity 15ms linear;
     }

    .collapsibleSection {
        >div {
            margin-bottom: 2rem;

        }

        .collapsibleSectionHead {
            .collapsibleSectionHeadLine {
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 28px;
                color: #C25700;
                width: 96%;
            }


        }

        .collapsibleSectionBodyContent {
            font-style: normal;
            font-weight: normal;
            font-size: .9rem;
            line-height: 1.5rem;

            br {
                content: "";
                margin: 2em;
                display: block;
                font-size: 24%;
            }

            img {
                max-width: 100%;
            }
        }


        .collapsibleSection {
            border-bottom: 1px solid #D9D9D9;
        }
    }
}
