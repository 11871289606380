@import "src/client_customizations/scss/client_master.scss";

.ChargingMap {
	padding-left:0;
	padding-right:0;
	padding-top:2px;
	
	.spinner-container {
		margin: 0 auto;
		text-align: center;
	}
	.map-container {
		position: relative;
		height: 552px;
		width: 100%;
		border: $ChargingMap-map-container-border;
		border-radius: 4px;
		margin-bottom: 0px !important;
		.legal-disclaimer {
			text-align: center;
		}
		a {
			padding: 8px 0 !important;
		}
	}
	.sidebar-container {
		position: absolute;
		left: 5px;
		top: 5px;
		z-index: 1;
	}
	.legend {
		background: $ChargingMap-legend-background;
		border: $ChargingMap-legend-border;
		border-radius: $ChargingMap-legend-border-radius;
		font-size: $ChargingMap-legend-font-size;
		.charger-type {
			position: relative;
			padding-right: 2rem;
			margin-bottom: 1rem;
			&:last-child {
				margin-bottom: 0;
			}
			img {
				width: 25px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}
	.charging-station-link {
		span {
			color: #C25700;
			text-decoration: underline;
		}
	}
}
.DealerMap {
	.spinner-container {
		margin: 0 auto;
		text-align: center;
	}
	.map-container {
		position: relative;
		height: 552px;
		width: 100%;
		border: $ChargingMap-dealer-map-container-border;
		border-radius: $ChargingMap-dealer-map-container-border-radius;
		a {
			padding: 8px 0 !important;
		}
	}
	.sidebar-container {
		position: absolute;
		left: 5px;
		top: 5px;
		z-index: 1;
	}
	.legend {
		background: $ChargingMap-dealer-legend-background;
		border: $ChargingMap-dealer-legend-border;
		border-radius: $ChargingMap-dealer-legend-border-radius;
		padding: 15px;
		font-size: 0.75rem;
		max-width: 199px;
		margin-bottom: 10px;
		position: relative;
		padding-right: 2rem;
		margin-bottom: 1rem;
		.charger-type {
			&:last-child {
				margin-bottom: 0;
			}
			img {
				width: 25px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}
	.show-full-list-container {
		margin: 40px 0 25px 0;
		text-align: center;
	}

	.SelectedCard {
		position: absolute;
		width: 90%;
		bottom: 0;
		padding:1.5rem 1rem;

		a {
			color: #000;
		}
 
		@media(max-width:575px) {
			   width:100%;
			   margin-bottom:25px;
			   position: relative;
		}
	}

	.learn-how {
		color: #F06B00;
		text-decoration: underline;
		border: none;
		background: none;
	}
	.add-electrician{
		font-size: 16px;
		font-weight: 700;
	}
}


.Map {
	overflow: hidden;
}
.locationCardHolder {
	margin-top: 20px;
}

@media (max-width:600px) {
	.ChargingMap {
		.map-title {
			padding-left: 0px;
		}
		.map-text {
			padding-left: 2rem;
			h1 {
				text-align: left;
			}
		}
	}
	.stations-button {
		text-align: center;
		padding-top: 20px;
	}
}