@import "src/client_customizations/scss/client_master.scss";

#chargingMapBasics{
    background-color: white;
    .faqContainer{
        .link{
            color: #C25700;
            text-decoration: underline;
        }
    }
    
    .disclaimer{
        font-size: 12px;
        font-style: italic;
    }

    .charging-speed-network {
        .title {
            font-style: normal;
            font-weight: bold;
            font-size: 1rem;
            line-height: 1.25rem;
            text-align: center;
            text-transform: uppercase;
        }
        .charging-speed-row {
            background-color: #F2F2F2;
            border-left: 3px solid #152b3b;
            margin-bottom: 1rem;
            p {
                margin-bottom: 0px;
            }
        }
        .level {
            font-size: 14px;
            font-weight: bold;
        }
        .description {
            font-weight: lighter;
        }
        .charge-speed {
            border-left: 1px solid #DBDBDB;
        }
        .charge-description {
            padding-right: 0;
            padding-left: 8px;
        }
        .charge-speed-miles {
            font-weight: bold;
        }
        .charge-time {
            flex-wrap: nowrap;  
            align-items: center;
        }
    }
    .charging-network-table, .charging-network-header{
        background-color: #F2F2F2;
    }

    .charging-network-table {
        .network {
            font-weight: bold;
            border-top: 1px solid #DADADA;
            border-right: 1px solid #DADADA;
            border-bottom: 1px solid #DADADA;
            a {
                color: black;
                text-decoration: none;
                &:hover {
                    color: #F06B00;
                }
            }
        }
        .l2stations, .dcfcStations, .l2CostDivided {
            border: 1px solid #DADADA;
        }
        .l2Cost, .dcfcCostDivided {
            border-top: 1px solid #DADADA;
            border-left: 1px solid #DADADA;
            border-bottom: 1px solid #DADADA;
        }
    }

    .charging-network-header {
        .heading {
            font-weight: bold;
        }
    }

}

#chargingMapBasicsMobile {
    .ev-charging-speed,  .national-public-charging-networks, .public-charging-faq{
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.25rem;
        text-transform: uppercase;
        padding-top: 2rem;
        padding-bottom: 1.5rem;
    }
    .charging-speed-row-mobile {
        border-left: 3px solid #152b3b;
        flex-direction: column;
        margin-bottom: 12px;
        background-color: #F2F2F2;
        font-size: 14px;
        .level-row {
            justify-content: space-between;
            .level {
                font-size: 16px;
                font-weight: 700;
            }
        }
        .charge-time {
            flex-direction: column;
        }
        .charge-vehicle {
            display: flex;
            flex-direction: row;
            padding-left: 30px;
            p {
                margin: 0;
            }
        }
        .description {
            padding-left: 30px;
            p {
                font-weight: 300;
            }
        }
        .charge-speed-miles {
            padding-left: 30px;
            padding-top: 12px;
            p {
                margin: 0;
                display: flex;
                align-items: center;
            }
        }
        .charging-speed-divider {
            width: 80%;
            margin:auto;
            color: #DBDBDB;
        }
    }
    .charging-network-mobile {
        display: flex;
        justify-content: space-between;
        background-color: #F2F2F2;
        margin-bottom: 20px;
        a {
            color: black;
            text-decoration: none;
            &:hover {
                color: #F06B00;
            }
        }
        .charging-network-titles {
            padding-top: 16px;
            padding-bottom: 16px;
            span {
                font-weight: 700;
                font-size: 14px;
            }
        }
        .collapsibleSectionCollaspeButton{
            padding: 0;
        }
        .collapsibleSection {
            >div {
                margin-bottom: 0rem;
            }
        }
        .collapsibleSection {
            border-bottom: none;
        }
        .charging-speed-divider {
            width: 90%;
            margin:auto;
            color: #DBDBDB;
        }
        .network-body {
            background-color: white;
            margin: 1.5rem;
            border: 1px solid #DADADA;
            padding: 1rem 2rem;
        }
        .collapsibleSectionHead {
            padding-top: 1rem;
            padding-left: 10px;
            padding-right: 10px;
        }
        .collapse-head {
            padding-bottom: 1rem;
        }
    }
}