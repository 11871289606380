@import "src/client_customizations/scss/client_master.scss";
.Footer {
	background-color: $GenericFooter-background-color;
	color: $GenericFooter-color;
	.h4 {
		margin-bottom: 5px;
		font-size: $GenericFooter-heading-font-size;
		color: $GenericFooter-heading-color;
		font-weight: 500;
	}
	a {
		transition: 0.3s all ease;
		-webkit-transition: 0.3s all ease;
		font-size: $GenericFooter-links-font-size;
		line-height: $GenericFooter-links-line-height;
		color: $GenericFooter-links-color;
		&:hover {
			color: $GenericFooter-links-hover-color;
			padding-left: 5px;
			text-decoration: underline;
		}
	}
	p {
		color: $GenericFooter-links-color;
		font-size: $GenericFooter-links-font-size;
		margin-bottom: 0px;
	}
	.zappyride {
		font-size: 9px;
		color: $GenericFooter-heading-color;
		font-style: italic;
		font-weight: 500;
	}
	.home-charging-popout {
		width: 16px;
		padding-left: 4px;
		padding-bottom: 2px;
	}
	.more-sites-popout {
		width: 20px;
		padding-left:4px;
		padding-bottom: 2px;
	}

	.disclaimer_container {
		font-family: Arial, Helvetica;
		display: flex;
		align-items: center;
		font-size: 10px; /* Set the font size to 10px */
		div {
			   background-color:transparent !important;
	
			   img {
					  max-width: 100%;
					  margin:0 0 25px;
			   }
	
			   p {
					  color:white;
			   }
		}
	}

	a {
		padding: 4px 0;
	}
}