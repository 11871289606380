@import "src/client_customizations/scss/client_master.scss";

section.container.homepage-jumbotron {
	display: flex;
	align-items: center;
}
.homepage-jumbotron {
	.big-promise {
		padding: $HomePage-big-promise-padding;
		background-color: rgba(0, 57, 99, 0.5);
		color: $HomePage-big-promise-color;
		text-shadow: $HomePage-big-promise-text-shadow;
		min-height: $HomePage-big-promise-min-height;
		width: 100%;
	}
	.hp-header-holder {
		margin-right: auto;
		margin-left:auto;
		width: fit-content;
	}
	.hp-welcome {
		padding: 20px 20px 40px 20px;
	}
	.hp-welcome-sub {
		padding: 40px 20px 20px 20px;
		text-align: center;
		font-size: 1.55rem;
	}
}
#link-card-buttons {
	margin-top: -7rem;
	padding: 3rem 1rem 1rem 1rem;
	background: transparent;
}
#why-choose-evs {
	padding: 0rem 1rem;
}
.big-promise-caption {
	top: 0;
	left: 0;
	position: absolute;
	padding: 1.5rem 1rem;
}
#HomepageVehiclesCarousel {
	padding-left: 12rem;
	padding-right: 12rem;
	padding-top: 60px;
}
#HomepageIncentives {
	padding-left: 12rem;
	padding-right: 12rem;
	background-image: url('../../assets//images/incentive_background.jpg');
	background-color: rgba(0,0,0,0.3);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	// height: 780px;
	display: flex;
	flex-direction: column;
	h2 {
		color:#fff;
		padding-top:30px;
		padding-bottom: 30px;
	}
	.explore-incentives-container {
		text-align: center;
		margin-top: auto;
		margin-bottom: 24px;
		padding-top: 20px;
	}
	.legal-disclaimer {
		padding-top: 20px;
		color: #fff
	}
	.render-cards-container {
		.evc-card {
			width: auto;
		}
	}
}

.homepage-sub-title {
	font-size: 1.25rem
}

.homepage-incentive {
	height: 26rem;
}

.down-arrow {
	font-size: 2rem;
}

.line{
	width: 40%;
	background-color: white;
	height: 4px;
	margin-left: 12rem;
}

.ad-container {
       margin-top:50px;
       margin-bottom:25px;
       text-align: center;
}


@media (max-width:600px) {
	#HomepageVehiclesCarousel {
		padding-left: 2rem;
		padding-right: 2rem;
	}
	#HomepageIncentives {
		padding-left: 2rem;
		padding-right: 2rem;
		.explore-incentives-container {
			text-align: center;
		}
		.legal-disclaimer {
			padding-top: 12px;
			font-size: 8px;
		}
		h2 {
			padding-top: 12px;
			padding-bottom: 12px;		
		}
	}
	.render-incentives {
		flex-direction: column;
	}
	.discover-incentives {
		padding-top: 8px;
		padding-bottom: 8px;
	}
	.render-incentives-container {
		margin-top: 0;
	}
	.line{
		width: 40%;
		background-color: white;
		height: 4px;
		margin-left: 6rem;
	}
}


