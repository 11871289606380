@import "src/client_customizations/scss/client_master.scss";

/* Apply yellow span underline for mobile views */
.Header {
	background-color: $Header-background-color;
	// border-bottom: $Header-border-bottom;
	a {
		color:#ffffff;
		font-weight: 500;
	}
	.navbar {
		padding-top: 0;
		padding-bottom: 0;
		background-color: $svce-darkBlue;
		span {
			font-size: 16px;
		}
		.navbar-brand {
			padding-top: 0;
			padding-bottom: 0;
			img {
				width: 160px;
			}
		}
		.navbar-collapse {
			a.active {
				span {
					color: #FF7B0F;
					text-decoration: none;
				}
			}

      .navbar-nav {
        align-items: center;
      }

      .dropdown-item {
        border-radius: 0;
      }
		}

    .dropdown-menu {
      top: 50px;
      border-radius: 3px;

      .dropdown-item {
        font-size: 13px;


        &.active,
        &:active {
          background-color: transparent;
          color: $Header-navbar-link-color;
        }
      }
    }
	}
	.header-left {
		display: flex;
	}
	.powered-by {
		align-self: flex-end;
	}
	.dropdown-item {
		padding-left: 20px;
		padding-right: 20px;
	}
	.navbar-nav > li > .dropdown-menu a:hover { 
		background-color: $svce-darkBlue;
		color: #fff;
	}

	.navbar-svce {
		padding-right: 4rem;
	}
	.nav-item {
		margin: 5px;
		display: flex;
		justify-content: center;
	}
	.dropdown-menu {
		left: auto;
		a {
			color: $svce-darkBlue;
			text-align: center;
		}
	}
	.tool-name {
		color: $svce-darkBlue;
		padding-left: 4rem;
		padding-right: .5rem;
		font-size: 32px;
		font-weight: 700;
	}
	.tool-subname {
		color: $svce-darkBlue;
		font-style: italic;
	}
	.dropdown-right-item {
		white-space:normal
	}
}
.skip-to-content-link {
	position: absolute;
	top: -1000px;
	left: -1000px;
	height: 1px;
	width: 1px;
	text-align: left;
	overflow: hidden;
}
a.skip-to-content-link {
	&:active {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:focus {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:hover {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
}
.navbar-toggler {
	border-color: $Header-navbar-toggler-border-color;
	color:$Header-navbar-toggler-color;
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(241,92,42, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}
@media (min-width: 992px) {
	.Header {
		.navbar {
			padding: 0;
			.navbar-collapse {
				a {
					padding: 10px 20px;
					border-radius: 4px;
					font-weight: 500;
					letter-spacing: 0.6px;
					text-decoration: none;
				}
				a.active {
					font-weight: bold;
				}
			}
		}
		.img-fluid {
			max-width: 266px;
                     padding-left: 10px;
		}
		.svce-logo {
			max-width: 180px;
		}
		.language-toggle {
			border-left: 1px solid white;
		}
	}
}

@media (max-width: 992px) {
	.Header {
		.navbar {
			padding: 0;
			.navbar-collapse {
				a {
					padding: 10px 20px;
					border-radius: 4px;
					font-weight: 500;
					letter-spacing: 0.6px;
					text-decoration: none;
				}
				a.active {
					font-weight: bold;
				}
			}
		}
		.img-fluid {
                     max-width: 266px;
                     padding-left: 10px;
		}
		.svce-logo {
			max-width: 180px;
		}
		.header-right {
			display: flex;
			align-items: center;
			padding-right: 2rem;
		}
		.tool-name{
			padding-left: 2rem;
		}
		.mobile-hamburger {
			background-color: white;
		}
		.navbar-svce {
			padding-right: 0px;
		}
	}
}

@media (max-width:800px) {
	.Header {
		.navbar-svce {
			padding-right: 0px;
		}
		.tool-name {
			color: $svce-darkBlue;
			font-size: 25px;
			font-weight: 700;
			padding-right: 0px;
			padding-left: 0px;
			padding-bottom:10px;
		}
		.header-left {
			flex-direction: column;
			align-items: center;
			padding-left: 1rem;
		}
		.powered-by {
			display: flex;
			align-items: center;
			font-size: 10px;
			margin-top: -15px;
		}
		.img-fluid {
			max-width: 150px;
		}
		.navbar-brand {
			margin-right: 0px;
		}
		.svce-logo {
			padding-right: .5rem;
			max-width: 110px;
		}
		.header-right {
			display: flex;
			align-items: center;
		}
		.mobile-hamburger {
			background-color: white;
		}
		.dropdown-menu {
			position: absolute;
		}

	}
}