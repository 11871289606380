@import "src/client_customizations/scss/client_master.scss";

.filter-btns {
	margin-top: 50px;
}
.ev-filter-btn{
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border: none;
	padding: 15px 25px;
	cursor: pointer;
	width: 49%;
	outline:none;
	margin-bottom: 10%;
}

.buttonActiveStyle {
	background-color: #C25700;
	color: #ffffff;
}

.buttonInactiveStyle {
	background-color: #ffffff;
	color: #000000;
}