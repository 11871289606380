@import "src/client_customizations/scss/client_master.scss";

.IncentiveCard {
	// flex-basis: 30%;
	// margin-right: 1%;
	// margin-bottom: 1%;
	margin: 1%;
}
.IncentiveCardBody {
	padding: 1rem;
	text-align: center;
	color: $IncentiveCard-card-body-color;
	border-radius: 0;
	.card-title {
		margin-top: .75rem;
		color: $svce-darkBlue;
	}
	img {
		margin-top: -12px;
		margin-bottom: -16px;
		width: 60px;
	}
}
.IncentiveCardBottom {
	padding: 1rem;
	text-align: center;
}
@media (min-width: 768px) {
	.IncentiveCard {
		flex-basis: 30%;
		width: 48%;
		margin: 1%;
	}
	.IncentiveCardBody {
		padding: 1.5rem;
	}ac
	.IncentiveCardBottom {
		padding: 1.5rem;
	}
}

@media (max-width:600px) {
	.IncentiveCard {
		width: 48%;
		// flex-basis: ;
	}
	.IncentiveCardBody {
		padding-bottom: 0px;
		.h1 {
			font-size: 16px;
		}
	}
	.IncentiveCardBottom {
		font-size: 11px;
		padding-top: 0px;
	}
}