@import "src/client_customizations/scss/client_master.scss";

.LocationCard {
	background-color: $ChargingStation-card-background-color;
	padding: 10px;
	border-radius: 4px;
	border: 1px solid #dbdbdb;
	display: block;
	margin: 15px 0;
	text-align: center;
}